import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NavigationLink = ({
  id,
  label,
  icon,
  isActive,
  setActivePage,
  disabled,
}) => {
  return (
    <li>
      <a
        href={`#${id}`}
        className={`${isActive && 'is-active'} ${disabled && 'disabled'}`}
        onClick={() => !disabled && setActivePage(id)}
      >
        <FontAwesomeIcon
          icon={icon}
          className={`fas fa-fw fa-lg fa-${icon}`}
          aria-hidden="true"
        />
        {label}
      </a>
    </li>
  );
};

NavigationLink.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  setActivePage: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default NavigationLink;
