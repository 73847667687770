export function checkClaims(requiredClaims, userData) {
  let claimsAuthorized = true;

  requiredClaims.forEach((c) => {
    // if the claim isn't present then we know the user isn't authorized
    if (!userData[c.claim]) {
      claimsAuthorized = false;
    } else {
      // if the claim was present, we need to ensure the use has the correct value
      if (!checkClaimForValue(c.claim, c.value, userData)) {
        claimsAuthorized = false;
      }
    }
  });

  return claimsAuthorized;
}

function checkClaimForValue(claim, value, userData) {
  if (Array.isArray(userData[claim])) {
    return userData[claim].some((x) => x === value);
  } else {
    return userData[claim] === value;
  }
}
