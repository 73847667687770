import React from 'react';
import PropTypes from 'prop-types';
import ContactTable from '../../../../Components/ContactTable';
import * as identity from '../../../../clients/identity';
import { Alert } from 'reactstrap';

const ContactPage = ({ account, forceUpdate, setHeader }) => {
  const emails = account.emails;
  const phones = account.phoneNumbers;

  const handleAddEmail = async (item) => {
    try {
      if (!validateEmail(item)) {
        createAlert('danger', `${item} is not a valid email address.`);
        return;
      }
      await identity.addEmail(item);
      account.emails = [
        ...emails,
        { value: item, verified: false, isAlias: false },
      ];
      createAlert('success', `A verification link has been sent to ${item}, if the email exists.`);
    } catch (error) {
      const errors = {
        'Email Already Exists': 'That email address is already in use.',
        default: 'There was a problem adding that email address.',
      };
      createAlert(
        'danger',
        errors[error.response.data.title] || errors['default'],
      );
    }
  };

  const handleAddPhone = async (item) => {
    try {
      if (!validatePhone(item)) {
        createAlert('danger', `${item} is not a valid phone number.`);
        return;
      }
      await identity.addPhone(item);
      account.phoneNumbers = [
        ...phones,
        { value: item, verified: false, isAlias: false },
      ];
      createAlert('success', `A verification link has been sent to ${item}, if the phone number exists.`);
    } catch (error) {
      const errors = {
        'Error Adding Phone Number': 'That phone number is already in use.',
        default: 'There was a problem adding that phone number.',
      };
      createAlert(
        'danger',
        errors[error.response.data.title] || errors['default'],
      );
    }
  };

  const handleRemoveEmail = async (item) => {
    try {
      await identity.removeEmail(item);
      account.emails = emails.filter((e) => e.value != item);
      createAlert(
        'success',
        `The email address ${item} was removed from your account.`,
      );
    } catch (error) {
      const errors = {
        default: 'There was a problem removing that email address.',
      };
      createAlert(
        'danger',
        errors[error.response.data.title] || errors['default'],
      );
    }
  };

  const handleRemovePhone = async (item) => {
    try {
      await identity.removePhone(item);
      account.phoneNumbers = phones.filter((e) => e.value != item);
      createAlert(
        'success',
        `The phone number ${item} was removed from your account.`,
      );
    } catch (error) {
      const errors = {
        default: 'There was a problem removing that phone number.',
      };
      createAlert(
        'danger',
        errors[error.response.data.title] || errors['default'],
      );
    }
  };

  const handleSetAlias = async (itemType, item) => {
    try {
      await identity.setAlias(itemType, item);

      if (itemType === 'email') {
        let newAliasIndex = account.emails.findIndex((x) => x.value === item);
        account.emails[newAliasIndex].isAlias = true;

        let oldAliasIndex = account.emails.findIndex((x) => x.isAlias == true);
        account.emails[oldAliasIndex].isAlias = false;
      }

      if (itemType === 'phone') {
        let newAliasIndex = account.phoneNumbers.findIndex(
          (x) => x.value === item,
        );
        account.phoneNumbers[newAliasIndex].isAlias = true;

        let oldAliasIndex = account.phoneNumbers.findIndex(
          (x) => x.isAlias == true,
        );
        account.phoneNumbers[oldAliasIndex].isAlias = false;
      }
      forceUpdate();
      createAlert('success', `Successfully set ${item} as a login.`);
    } catch (error) {
      const errors = {
        default: `There was a problem setting ${item} as a login.`,
      };
      createAlert(
        'danger',
        errors[error.response.data.title] || errors['default'],
      );
    }
  };

  const handleSendVerification = async (itemType, item) => {
    let email = itemType === 'email' ? item : '';
    let phone = itemType === 'phone' ? item : '';

    try {
      await identity.sendVerification(
        account.hstreamId,
        itemType,
        phone,
        email,
      );
     if (itemType === 'email')
     {
      createAlert('success', `A verification link has been resent to ${item}, if the email exists.`);
     }
    else if(itemType === 'phone')
    {
      createAlert('success', `A verification link has been resent to ${item}, if the phone number exists.`);
    }
    } catch (error) {
      const errors = {
        default: `There was a problem sending a verification link to ${item}.`,
      };
      createAlert(
        'danger',
        errors[error.response.data.title] || errors['default'],
      );
    }
  };

  function createAlert(type, text) {
    setHeader(
      <Alert
        color={type}
        isOpen={true}
        toggle={() => {
          setHeader(undefined);
        }}
      >
        {text}
      </Alert>,
    );
  }

  function validateEmail(email) {
    // eslint-disable-next-line no-useless-escape
    let regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  }

  function validatePhone(phone) {
    // ? if US make sure length is 12 including the '+' and country code
    if (phone.substring(0, 2) === '+1' && phone.length != 12) {
      return false;
    }

    // ? Only allow '+', '-', '(', ')', ' ', '.', '0-9'
    // ? Shortest possible number is 8 characters including the '+' and country code
    // ? E.164 specificies max length of 15, plus international prefix of '+'
    // eslint-disable-next-line no-useless-escape
    var regex = /^[\+\-\ .()0-9]{8,16}$/;
    return regex.test(phone);
  }

  return (
    <>
      <ContactTable
        title="Email Address"
        type="email"
        items={emails}
        addItem={handleAddEmail}
        removeItem={handleRemoveEmail}
        setAlias={handleSetAlias}
        sendVerification={handleSendVerification}
      />
      <ContactTable
        title="Phone Number"
        type="phone"
        items={phones}
        addItem={handleAddPhone}
        removeItem={handleRemovePhone}
        setAlias={handleSetAlias}
        sendVerification={handleSendVerification}
      />
    </>
  );
};

ContactPage.propTypes = {
  account: PropTypes.object.isRequired,
  forceUpdate: PropTypes.func.isRequired,
  setHeader: PropTypes.func.isRequired,
};

export default ContactPage;
