import React, { useContext, useEffect, useState } from 'react';
import MasterPage from '../../Components/MasterPage';
import { PortalAuthContext } from '../../Components/PortalIntegrator';
import { Router } from '@reach/router';
import { Spinner, Alert } from 'reactstrap';
import styled from 'styled-components';
import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faAddressBook,
  faBars,
  faCheck,
  faChevronRight,
  faEye,
  faEyeSlash,
  faLock,
  faPlus,
  faQuestionCircle,
  faTimes,
  faUser,
  faUserCircle,
  faLink,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import AccountManagement from '../AccountManagement';

library.add(
  faAddressBook,
  faBars,
  faCheck,
  faChevronRight,
  faLock,
  faEye,
  faEyeSlash,
  faPlus,
  faQuestionCircle,
  faTimes,
  faUser,
  faUserCircle,
  faLink,
  faExclamationTriangle,
);

const packageJson = require('../../../package.json');

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const App = () => {
  const authContext = useContext(PortalAuthContext);
  const [mobileNav, toggleMobileNav] = useState(false);
  const [siteTitle] = useState('Manage your hStream ID');

  const name = authContext.user
    ? `${authContext.user.account.firstName} ${authContext.user.account.lastName}`
    : '';

  useEffect(() => {
    if (authContext.tokenExpired) {
      authContext.redirectUserToHpa();
    }
  }, [authContext.tokenExpired]);

  const signOut = () => {
    authContext.signOut();
  };

  const signIn = () => {
    authContext.redirectUserToHpa();
  };

  if (authContext.loading) {
    return (
      <SpinnerWrapper>
        <Spinner color="primary" />
      </SpinnerWrapper>
    );
  }

  const appVersion = `Version: ${packageJson.version}`;
  console.log(appVersion);

  return (
    <MasterPage
      loggedIn={authContext.loggedIn}
      error={authContext.error}
      siteTitle={siteTitle}
      signOut={signOut}
      signIn={signIn}
      name={name}
      toggleMobileNav={toggleMobileNav}
    >
      {authContext.error ? (
        <>
          {authContext.error === 'transient' ? (
            <Alert color="danger">
              An error has occurred. Please try signing in again.
            </Alert>
          ) : (
            <Alert color="danger">
              An error has occurred. Please try again or contact customer
              service for further assistance at 1-800-521-0574.
            </Alert>
          )}
        </>
      ) : (
        <Router className="site__card-body">
          <AccountManagement
            path="/*"
            mobileNav={mobileNav}
            toggleMobileNav={toggleMobileNav}
            signOut={signOut}
            signIn={signIn}
          />
        </Router>
      )}
    </MasterPage>
  );
};

export default App;
