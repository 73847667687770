import { blacklist } from '../assets/PasswordBlacklist';

const MIN_LENGTH = 8;
const MAX_LENGTH = 64;
const sequences = [
  'abcd',
  'bcde',
  'cdef',
  'defg',
  'efgh',
  'fghi',
  'ghij',
  'hijk',
  'ijkl',
  'jklm',
  'klmn',
  'lmno',
  'mnop',
  'nopq',
  'opqr',
  'pqrs',
  'qrst',
  'rstu',
  'stuv',
  'tuvw',
  'uvwx',
  'vwxy',
  'wxyz',
  '0123',
  '1234',
  '2345',
  '3456',
  '4567',
  '5678',
  '6789',
];

export const required = (value) => (value ? undefined : 'Required');

export const minLength = (value) => {
  if (value.length < MIN_LENGTH) {
    return `Password must be at least ${MIN_LENGTH} characters`;
  }
  return undefined;
};

export const matchesPassword = (newPassword, confirmPassword) => {
  if (newPassword == confirmPassword) {
    return undefined;
  }
  return 'Passwords must match';
};

export const noRepeatingCharacters = (value) => {
  const repeatRegex = RegExp('([a-z\\d])\\1\\1', 'i');

  if (repeatRegex.test(value)) {
    return 'Repeating characters are not allowed, ex: "aaa", "111"';
  }
  return undefined;
};

export const maximumLength = (value) => {
  if (value.length > MAX_LENGTH) {
    return `Password must not be longer than ${MAX_LENGTH} characters`;
  }
  return undefined;
};

export const noEndSpaces = (value) => {
  if(value.length!= value.trim().length)
  {
    return `Password must not contain leading or trailing spaces.`;
  }
  return undefined;
}

export const noSequentialCharacters = (value) => {
  let sequenceMatched = false;

  sequences.forEach((s) => {
    if (value.toLowerCase().indexOf(s) !== -1) {
      sequenceMatched = true;
    }
  });

  if (sequenceMatched) {
    return 'Sequential characters are not allowed, ex: "abcd", "1234"';
  }
};

export const notCommonPassword = (value) => {
  const sanitizedValue = value.replace(/\u200B/g, '');
  const normalizedValue = sanitizedValue.trim();
  const commonPassword = blacklist.find((x) => x === normalizedValue);
  if (commonPassword) {
    return 'Please choose a more unique password.';
  }

  return undefined;
};

const capitalLetterRegex = /.*[A-Z]/;
export const containsCapitalLetter = (value) => {
  if (capitalLetterRegex.test(value)) {
    return undefined;
  }
  return 'Password must contain a capital letter';
};

const lowerCaseLetterRegex = /.*[a-z]/;
export const containsLowerCaseLetter = (value) => {
  if (lowerCaseLetterRegex.test(value)) {
    return undefined;
  }
  return 'Password must contain a lower-case letter';
};

const numberRegex = /.*[0-9]/;
export const containsNumber = (value) => {
  if (numberRegex.test(value)) {
    return undefined;
  }
  return 'Password must contain a number';
};

const specialCharRegex = /.*[.,<>:;|_~`'"!@#/\-?[\]\\(){}$%^&=+*]/;
export const containsSpecialCharacter = (value) => {
  if (specialCharRegex.test(value)) {
    return undefined;
  }
  return 'Password must contain a special character';
};
