import React from 'react';
import PropTypes from 'prop-types';
import HstreamLogo from './components/HstreamLogo';
import UserAvatar from './components/UserAvatar';
import { Button } from 'reactstrap';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MasterPage = ({
  loggedIn,
  siteTitle,
  name,
  signIn,
  signOut,
  navigate,
  toggleMobileNav,
  children,
}) => {
  const SignOut = styled.div`
    text-align: right;

    @media (max-width: 767.98px) {
      display: none;
    }
  `;

  const SignIn = styled.div`
    text-align: right;

    @media (max-width: 767.98px) {
      display: none;
    }
  `;

  return (
    <div className="site">
      <div className="site__container">
        <div className="site__card">
          <div className="site__card-header">
            <div>
              <div className="site__branding">
                <HstreamLogo navigate={navigate}></HstreamLogo>
              </div>
              <div className="site__title">{siteTitle}</div>
            </div>
            <div>
              <>
                {loggedIn ? (
                  <>
                    <UserAvatar name={name} signOut={signOut} />
                    <SignOut>
                      <Button color="link" onClick={() => signOut()}>
                        Sign Out
                      </Button>
                    </SignOut>
                  </>
                ) : (
                  <SignIn>
                    <Button
                      onClick={() => signIn()}
                      type="button"
                      color="primary"
                    >
                      Sign In
                    </Button>
                  </SignIn>
                )}
              </>
            </div>
            <div className="site__nav-open">
              <Button color="link" onClick={() => toggleMobileNav(true)}>
                <FontAwesomeIcon
                  icon="bars"
                  className="fas fa-fw fa-lg fa-bars"
                  aria-hidden="true"
                />
              </Button>
            </div>
          </div>
          {children}
          <div className="site__card-footer"></div>
        </div>
        <div className="site__footer">
          Copyright &copy; {new Date().getFullYear()} HealthStream, Inc. All
          Rights Reserved.
        </div>
      </div>
    </div>
  );
};

MasterPage.propTypes = {
  loggedIn: PropTypes.bool,
  siteTitle: PropTypes.string,
  name: PropTypes.string.isRequired,
  signIn: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
  navigate: PropTypes.func,
  toggleMobileNav: PropTypes.func.isRequired,
  children: PropTypes.any,
};

MasterPage.defaultProps = {
  loggedIn: undefined,
  siteTitle: undefined,
  navigate: undefined,
};

export default MasterPage;
