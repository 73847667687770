import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button } from 'reactstrap';
import PasswordInput from '../../../../Components/PasswordInput';
import { changePassword } from '../../../../clients/identity';
import {
  required,
  minLength,
  matchesPassword,
  noRepeatingCharacters,
  noSequentialCharacters,
  noEndSpaces,
  notCommonPassword,
  containsCapitalLetter,
  containsLowerCaseLetter,
  containsNumber,
  containsSpecialCharacter
} from '../../../../helpers/validate';
import { PortalAuthContext } from '../../../../Components/PortalIntegrator';

const PasswordPage = ({ setHeader }) => {
  const { passwordComplexityFeature } = useContext(PortalAuthContext);
  const [showPassword, toggleShowPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [currentPasswordValidation, setCurrentPasswordValidation] = useState(
    '',
  );
  const [newPasswordValidation, setNewPasswordValidation] = useState('');
  const [confirmPasswordValidation, setConfirmPasswordValidation] = useState(
    '',
  );

  async function handleFormSubmit() {
    try {
      if (validatePassword()) {
        await changePassword(currentPassword, newPassword);
        createAlert('success', 'Your password was updated.');
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
      }
    } catch (error) {
      const errors = {
        default: 'There was a problem changing the password.',
        incorrectPassword:
          'Incorrect password. Please check your current password and try again.',
      };

      let message = errors['default'];
      if(error.response.data.detail === 'Incorrect username or password.')
        {message = errors['incorrectPassword'];}
      else if (error.response.data.title === "Password Not Valid")
        {message = error.response.data.detail;}
      createAlert('danger', message);
    }
  }

  function createAlert(type, text) {
    setHeader(
      <Alert
        color={type}
        isOpen={true}
        toggle={() => {
          setHeader(undefined);
        }}
      >
        {text}
      </Alert>,
    );
  }

  function validatePassword() {
    let validations = {
      currentPasswordRequired: required(currentPassword),
      newPasswordRequired: required(newPassword),
      confirmPasswordRequired: required(confirmPassword),
      passwordsMatch: matchesPassword(newPassword, confirmPassword),
      newPasswordLength: minLength(newPassword),
      newPasswordRepeating: noRepeatingCharacters(newPassword),
      newPasswordSequential: noSequentialCharacters(newPassword),
      newPasswordEndSpaces: noEndSpaces(newPassword),
      newPasswordCommon: notCommonPassword(newPassword),
      confirmPasswordLength: minLength(confirmPassword),
      confirmPasswordRepeating: noRepeatingCharacters(confirmPassword),
      confirmPasswordSequential: noSequentialCharacters(confirmPassword),
      confirmPasswordEndSpaces: noEndSpaces(confirmPassword),
      confirmPasswordCommon: notCommonPassword(confirmPassword),
    }
    if (passwordComplexityFeature)
    {
      validations = {
      currentPasswordRequired: required(currentPassword),
      newPasswordRequired: required(newPassword),
      confirmPasswordRequired: required(confirmPassword),
      passwordsMatch: matchesPassword(newPassword, confirmPassword),
      newPasswordLength: minLength(newPassword),
      newPasswordRepeating: noRepeatingCharacters(newPassword),
      newPasswordSequential: noSequentialCharacters(newPassword),
      newPasswordEndSpaces: noEndSpaces(newPassword),
      newPasswordCommon: notCommonPassword(newPassword),
      newPasswordCapitalLetter: containsCapitalLetter(newPassword),
      newPasswordLowerCaseLetter: containsLowerCaseLetter(newPassword),
      newPasswordNumber: containsNumber(newPassword),
      newPasswordSpecialCharacter: containsSpecialCharacter(newPassword),
      confirmPasswordLength: minLength(confirmPassword),
      confirmPasswordRepeating: noRepeatingCharacters(confirmPassword),
      confirmPasswordSequential: noSequentialCharacters(confirmPassword),
      confirmPasswordEndSpaces: noEndSpaces(confirmPassword),
      confirmPasswordCommon: notCommonPassword(confirmPassword),
      confirmPasswordCapitalLetter: containsCapitalLetter(confirmPassword),
      confirmPasswordLowerCaseLetter: containsLowerCaseLetter(confirmPassword),
      confirmPasswordNumber: containsNumber(confirmPassword),
      confirmPasswordSpecialCharacter: containsSpecialCharacter(confirmPassword),
    };
  }

    setCurrentPasswordValidation(validations.currentPasswordRequired);
 
    setNewPasswordValidation(
      validations.newPasswordRequired ||
        validations.newPasswordLength ||
        validations.newPasswordCapitalLetter ||
        validations.newPasswordLowerCaseLetter ||
        validations.newPasswordNumber ||
        validations.newPasswordSpecialCharacter ||
        validations.newPasswordRepeating ||
        validations.newPasswordSequential ||
        validations.newPasswordCommon ||
        validations.newPasswordEndSpaces,
    );
    setConfirmPasswordValidation(
      validations.confirmPasswordRequired || 
      validations.confirmPasswordLength ||
      validations.confirmPasswordCapitalLetter ||
      validations.confirmPasswordLowerCaseLetter ||
      validations.confirmPasswordNumber ||
      validations.confirmPasswordSpecialCharacter ||
      validations.confirmPasswordRepeating ||
      validations.confirmPasswordSequential ||
      validations.confirmPasswordCommon ||
      validations.confirmPasswordEndSpaces ||
      validations.passwordsMatch,
    );
  
    const values = Object.values(validations);
    const isValid = values.every((e) => typeof e === 'undefined');
    return isValid;
  }

  return (
    <>
      <PasswordInput
        name="currentPassword"
        label="Current Password"
        value={currentPassword}
        setValue={setCurrentPassword}
        validation={currentPasswordValidation}
        showPassword={showPassword}
        toggleShowPassword={toggleShowPassword}
      />
      <PasswordInput
        name="newPassword"
        label="New Password"
        value={newPassword}
        setValue={setNewPassword}
        validation={newPasswordValidation}
        showPassword={showPassword}
        toggleShowPassword={toggleShowPassword}
      />
      <PasswordInput
        name="confirmPassword"
        label="Confirm Password"
        value={confirmPassword}
        setValue={setConfirmPassword}
        validation={confirmPasswordValidation}
        showPassword={showPassword}
        toggleShowPassword={toggleShowPassword}
      />
      <Button color="primary" name="resetPasswordButton" onClick={async () => await handleFormSubmit()}>
        Update
      </Button>
    </>
  );
};

PasswordPage.propTypes = {
  setHeader: PropTypes.func.isRequired,
};

export default PasswordPage;
