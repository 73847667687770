import styled from 'styled-components';

export const NrpCardWrapper = styled.div`
  border-bottom-left-radius: ${(props) =>
    props.dropdownOpen ? '25px' : '0px'};
  border-bottom-right-radius: ${(props) =>
    props.dropdownOpen ? '25px' : '0px'};
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  max-width: 360px;
  -webkit-box-shadow: 6px 10px 10px 4px #d1d1d1;
  box-shadow: 6px 10px 10px 4px #d1d1d1;
  border-style: thin;
  padding: 1rem 1rem 0 1rem;
  margin-left: 1rem;

  .row {
    justify-content: space-between;
    align-items: baseline;
  }
`;

export const NrpCardTitle = styled.div`
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-bottom: 0.2rem;
  color: #467cbf;
  font-weight: 600;
  border-color: #595959;
  border-width: thin;
  border-style: solid;
  border-top: none;
  border-left: none;
  border-right: none;
  margin-bottom: 0.5rem;
  width: 100%;
`;

export const NrpLineItem = styled.p`
  padding-left: 0.5rem;
  margin-bottom: 0;
  margin-top: 0;
`;

export const NrpLineItemStatus = styled.p`
  padding-left: 0.5rem;
  margin-bottom: 0;
  margin-top: 0;
  tablecell;
  strong {
    color: ${(props) => (props.active === 'Active' ? '#66bc66' : 'red')};
  }
`;

export const NrpLogoWrapper = styled.div`
  height: fit-content;
  margin-left: 1rem;
`;

export const NrpLogo = styled.img`
  max-height: 50px;
  margin-left: auto;
  border-radius: 25x;
  border-bottom-right-radius: ${(props) => (props.dropdownOpen ? '0' : '25px')};

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    border-bottom-right-radius: ${(props) =>
      props.dropdownOpen ? '0' : '25px'};
  }
  @media screen and (max-width: 362px) {
    display: none;
  }
`;

export const LinkedOrganizationsButton = styled.button`
  display: flex;
  border: 0;
  background: transparent;
  color: #467cbf;
  font-size: small;
  margin: 0 0.5rem 0 1rem;
  outline-color: transparent;
  outline-style: none;
  -webkit-focus-ring-color: transparent;
  :focus {
    outline-color: transparent;
    outline: 5px auto transparent;
  }
  p {
    margin: 0 0.5rem 0 0;
    padding-bottom: 0;
  }

  svg {
    margin-top: 4px;
  }

  @media screen only (max-width: 362px) {
    margin: 1rem 0;
  }
`;

export const OrganizationsDropdown = styled.div`
  background-color: #f2f2f2;
  border-bottom-left-radius: 25px !important;
  border-bottom-right-radius: 25px !important;
  padding-left: 1rem;
  p {
    color: #919192;
    font-size: 14px;
    font-style: italic;
    padding-left: 1rem;
    border-bottom-left-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
    margin-bottom: 0.5rem;
  }
`;

export const LinkedOrganizationsWrapper = styled.div`
  background-color: #f2f2f2;
  border-bottom-radius: 25px;
  border-bottom-left-radius: 25px !important;
  border-bottom-right-radius: 25px !important;
  padding: 0.25rem 0;
`;

export const DropdownWrapper = styled.div`
  -webkit-box-shadow: 6px 10px 10px 0px #d1d1d1;
  box-shadow: 6px 10px 10px 0px #d1d1d1;
  max-width: 360px;
  margin-left: 1rem;
  border-bottom-left-radius: 25px !important;
  border-bottom-right-radius: 25px !important;
`;

export const LinkedOrganizationsRow = styled.div`
  padding-bottom: 0;
  @media screen and (max-width: 362px) {
    padding: 1rem 0;
  }
`;
