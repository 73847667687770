import React from 'react';
import PropTypes from 'prop-types';

const HstreamLogo = ({ navigate }) => {
  const handleNavClick = (e) => {
    e.preventDefault();
    navigate(e.target.id);
  };

  return (
    <a href="/" onClick={handleNavClick} id="/" title="My hStream">
      <img style={{width: `100%`}} src= '/assets/hstream_logo.png'></img>
    </a>
  );
};

HstreamLogo.propTypes = {
  navigate: PropTypes.func,
};

HstreamLogo.defaultProps = {
  navigate: undefined,
};

export default HstreamLogo;
