import axios from 'axios';
import newRelic from '../helpers/newRelic';

const config = window['myhstreamRuntimeConfig'];

let apiConfig = {
  baseURL: config && config.identityApiBaseUrl,
  headers: { 'Content-Type': 'application/json' },
};

let myhstreamServiceConfig = {
  baseURL: config && config.myHstreamServiceBaseUrl,
  headers: { 'Content-Type': 'application/json' },
};
export async function getAccount() {
  try {
    const token = localStorage.getItem('Hstm.Identity.AccessToken');
    apiConfig.headers.Authorization = token;
    return axios.get('/api/myaccount', apiConfig);
  } catch (error) {
    newRelic.noticeError(error);
    throw error;
  }
}

export async function userInfo() {
  try {
    const token = localStorage.getItem('Hstm.Identity.AccessToken');
    apiConfig.headers.Authorization = token;
    return await axios.get('/userinfo', apiConfig);
  } catch (error) {
    newRelic.noticeError(error);
    throw error;
  }
}

export async function addEmail(email) {
  try {
    const token = localStorage.getItem('Hstm.Identity.AccessToken');
    apiConfig.headers.Authorization = token;
    const referrerheader = localStorage.getItem('Referrer');
    apiConfig.headers.Referrerheader = referrerheader;
    await axios.post('/api/myaccount/addemail', { email }, apiConfig);
  } catch (error) {
    newRelic.noticeError(error);
    throw error;
  }
}

export async function removeEmail(email) {
  try {
    const token = localStorage.getItem('Hstm.Identity.AccessToken');
    apiConfig.headers.Authorization = token;
    await axios.post('/api/myaccount/removeemail', { email }, apiConfig);
  } catch (error) {
    newRelic.noticeError(error);
    throw error;
  }
}

export async function addPhone(phone) {
  try {
    const token = localStorage.getItem('Hstm.Identity.AccessToken');
    apiConfig.headers.Authorization = token;
    const referrerheader = localStorage.getItem('Referrer');
    apiConfig.headers.Referrerheader = referrerheader;
    await axios.post('/api/myaccount/addphonenumber', { phone }, apiConfig);
  } catch (error) {
    newRelic.noticeError(error);
    throw error;
  }
}

export async function removePhone(phone) {
  try {
    const token = localStorage.getItem('Hstm.Identity.AccessToken');
    apiConfig.headers.Authorization = token;
    await axios.post('/api/myaccount/removephonenumber', { phone }, apiConfig);
  } catch (error) {
    newRelic.noticeError(error);
    throw error;
  }
}

export async function sendVerification(
  hstreamId,
  verificationMethod,
  phone,
  email,
) {
  try {
    const token = localStorage.getItem('Hstm.Identity.AccessToken');
    apiConfig.headers.Authorization = token;
    const referrerheader = localStorage.getItem('Referrer');
    apiConfig.headers.Referrerheader = referrerheader;

    const data = {
      message: {
        hstreamId,
        verificationMethod,
        phone: phone || '',
        email: email || '',
      },
    };

    await axios.post('/api/accounts/sendverification', data, apiConfig);
  } catch (error) {
    newRelic.noticeError(error);
    throw error;
  }
}

export async function setAlias(type, value) {
  try {
    const token = localStorage.getItem('Hstm.Identity.AccessToken');
    apiConfig.headers.Authorization = token;
    const referrerheader = localStorage.getItem('Referrer');
    apiConfig.headers.Referrerheader = referrerheader;
    await axios.post('/api/myaccount/setalias', { type, value }, apiConfig);
  } catch (error) {
    newRelic.noticeError(error);
    throw error;
  }
}

export async function changeName(firstName, lastName) {
  try {
    const token = localStorage.getItem('Hstm.Identity.AccessToken');
    apiConfig.headers.Authorization = token;
    await axios.post(
      '/api/myaccount/changename',
      { firstName, lastName },
      apiConfig,
    );
  } catch (error) {
    newRelic.noticeError(error);
    throw error;
  }
}

export async function changePassword(currentPassword, newPassword) {
  try {
    const token = localStorage.getItem('Hstm.Identity.AccessToken');
    apiConfig.headers.Authorization = token;
    const referrerheader = localStorage.getItem('Referrer');
    apiConfig.headers.Referrerheader = referrerheader;
    await axios.post(
      '/api/myaccount/changepassword',
      { currentPassword, newPassword },
      apiConfig,
    );
  } catch (error) {
    newRelic.noticeError(error);
    throw error;
  }
}

export async function getAffiliations() {
  try {
    const token = localStorage.getItem('Hstm.Identity.AccessToken');
    apiConfig.headers.Authorization = token;
    const response = await axios.get(
      `/api/myaccount/getaffiliations`,
      apiConfig,
    );
    return response.data;
  } catch (error) {
    newRelic.noticeError(error);
    throw error;
  }
}

export async function getLinkedApplications(hstreamId) {
  const token = localStorage.getItem('Hstm.Identity.AccessToken');
  myhstreamServiceConfig.headers.Authorization = `Bearer ${token}`;
  return await axios.get(
    `/myhstream/${hstreamId}/linkedApplications`,
    myhstreamServiceConfig,
  );
}

