import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const MobileUserNav = styled.div`
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2rem;
  border-bottom: 1px solid #dee2e6;
  width: 80%;
  text-align: center;
  margin-top: 2rem;
  .site__nav-mobile-user {
    font-size: 1.1rem;
  }
  .site__nav-mobile-logout {
    font-size: 0.9rem;
  }
  @media (min-width: 767.98px) {
    display: none;
  }
`;

const Navigation = ({
  mobileNav,
  toggleMobileNav,
  signOut,
  signIn,
  loggedIn,
  user,
  children,
}) => {
  const isActive = mobileNav ? 'is-active' : '';
  return (
    <nav className={`site__nav ${isActive}`}>
      <MobileUserNav>
        {loggedIn && user ? (
          <>
            <div className="site__nav-mobile-user">{`${user.account.firstName} ${user.account.lastName}`}</div>
            <Button
              color="link"
              className="site__nav-mobile-logout"
              onClick={() => signOut()}
            >
              Sign Out
            </Button>
          </>
        ) : (
          <>
            <Button color="primary" onClick={() => signIn()}>
              Sign In
            </Button>
          </>
        )}
      </MobileUserNav>
      <ul>{children}</ul>
      <Button
        color="link"
        className="site__nav-close"
        onClick={() => toggleMobileNav(false)}
      >
        <FontAwesomeIcon
          icon="times"
          className="fas fa-fw fa-lg fa-times"
          aria-hidden="true"
        />
      </Button>
    </nav>
  );
};

Navigation.propTypes = {
  mobileNav: PropTypes.bool.isRequired,
  toggleMobileNav: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
  signIn: PropTypes.func,
  loggedIn: PropTypes.bool.isRequired,
  user: PropTypes.object,
  children: PropTypes.any.isRequired,
};

Navigation.defaultProps = {
  signIn: undefined,
  user: undefined,
};

export default Navigation;
