import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Spinner, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';

const PageHeader = styled.p`
  font-weight: 600;
`;

const NoLinksMessage = styled.p`
  color: grey;
  margin-top: 1rem;
  padding-left: 1rem;
`;

const Application = ({ data }) => {
  const { application_id, application_name, affiliations, application_logo_url } = data;
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <div
      className="application"
      key={application_id}
      data-product-id={application_id}
    >
      <div onClick={handleToggle}>
        {affiliations && affiliations.length > 0 ?
          <Row className="m-0" style={{ paddingTop: '0.75rem', paddingBottom: '0.75rem' }}>
            <Col sm="3"><img style={{ width: `100%` }} src={application_logo_url}></img></Col>
            <Col sm="7" className="d-flex align-items-center application__name">
              <div className="application__name">{application_name}</div>
            </Col>
            <Col sm="2" className="d-flex align-items-center justify-content-end">
              {!expanded ? (
                <FontAwesomeIcon
                  style={{ fontSize: '1.1em', color: '#467CBF' }}
                  icon={faChevronDown}
                  id="faChevronDown"
                />) : (
                <FontAwesomeIcon
                  style={{ fontSize: '1.1em', color: '#467CBF' }}
                  icon={faChevronRight}
                  id="faChevronRight"
                />
              )}
            </Col>
          </Row> :
          <Row className="m-0" style={{ paddingTop: '0.75rem', paddingBottom: '0.75rem' }}>
            <Col sm="3"><img style={{ width: `100%` }} src={application_logo_url}></img></Col>
            <Col sm="9">
              <div className="application__name">{application_name}</div>
            </Col>
          </Row>
        }
      </div>

      {
        !expanded && affiliations.length > 0 && (
          <div className="application__affiliations">
            {affiliations.map((item) => (
              <div
                className="application__affiliation"
                key={item.affiliation_id}
                data-affiliation-id={item.affiliation_id}
              >
                <Row>
                  <Col sm="8"><div className="application__affiliation-name">{item.affiliation_name}</div></Col>
                </Row>
              </div>

            ))}
          </div>
        )
      }
    </div >
  );
};

Application.propTypes = {
  data: PropTypes.shape({
    application_id: PropTypes.string,
    application_name: PropTypes.string,
    affiliations: PropTypes.array,
    application_logo_url: PropTypes.string,
  })
}

const LinkedApplicationsPage = ({ linkedApplicationsdata, isLoading }) => {
  return (
    <div className="linksPage" width="50%">
      <PageHeader>Linked Applications</PageHeader>
      {isLoading ? <div className="d-flex justify-content-center mt-5" style={{ minHeight: '50px' }}>
        <Spinner color="primary" />
      </div>
        :
        <div>
          {linkedApplicationsdata && linkedApplicationsdata.length > 0 ? (
            linkedApplicationsdata.map((app) => (
              <Application data={app} key={app.application_id} />
            ))
          ) : (
            <NoLinksMessage id="no-links-message">
              You do not have any applications linked with your hStream ID yet.
            </NoLinksMessage>
          )}
        </div>
      }
    </div>
  );
};

LinkedApplicationsPage.propTypes = {
  linkedApplicationsdata: PropTypes.shape(),
  isLoading: PropTypes.bool,
};
LinkedApplicationsPage.defaultProps = {};

export default LinkedApplicationsPage;
