import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const ContactTable = ({
  title,
  type,
  items,
  addItem,
  removeItem,
  setAlias,
  sendVerification,
}) => {
  const [item, updateItem] = useState('');
  const [displayPhone, setDisplayPhone] = useState(true);

  useEffect(() => {
    setDisplayPhone(false);
  }, [items]);

  useEffect(() => {
    if (!displayPhone) {
      setDisplayPhone(true);
    }
  }, [displayPhone]);

  const handleUpdateEmail = (e) => {
    e.preventDefault();
    updateItem(e.target.value);
  };

  const handleUpdatePhone = (phone) => {
    phone = phone.replace(/[^0-9+]/g, '');
    updateItem(phone);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      addItem(item);
      updateItem('');
    }
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    addItem(item);
    updateItem('');
  };

  return (
    <div className="page__table">
      <Table striped>
        <thead>
          <tr>
            <th>{title}</th>
            <th>Verified</th>
            <th>Login With</th>
            <th>Remove</th>
          </tr>
        </thead>
        <tbody>
          {items && items.map((i) => {
            return (
              <tr key={i.value}>
                <td data-label={title}>{i.value}</td>
                <td data-label="Verified">
                  {i.verified ? (
                    <FontAwesomeIcon
                      icon="check"
                      className="fas fa-fw fa-check text-success"
                      aria-hidden="true"
                    />
                  ) : (
                    <Button
                      color="link"
                      className="btn-sm"
                      onClick={() => sendVerification(type, i.value)}
                      id={i.value}
                    >
                      Resend
                    </Button>
                  )}
                </td>
                <td data-label="Login With">
                  {i.isAlias ? (
                    <FontAwesomeIcon
                      icon="check"
                      className="fas fa-fw fa-check text-success"
                      aria-hidden="true"
                    />
                  ) : i.verified ? (
                    <Button
                      color="link"
                      className="btn-sm"
                      onClick={() => setAlias(type, i.value)}
                      id={i.value}
                    >
                      Set as Login
                    </Button>
                  ) : (
                    <>&mdash;</>
                  )}
                </td>
                <td data-label="Remove">
                  {i.isAlias ? (
                    <>&mdash;</>
                  ) : (
                    <Button
                      color="link"
                      className="btn-sm"
                      onClick={() => removeItem(i.value)}
                      id={i.value}
                    >
                      Remove
                    </Button>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="page__table-addition">
        <InputGroup className="input-group-sm">
          {type === 'email' ? (
            <Input
              type="email"
              placeholder={`Add ${title}`}
              value={item}
              onChange={handleUpdateEmail}
              onKeyDown={handleKeyDown}
            />
          ) : (displayPhone &&
            <PhoneInput
              country={'us'}
              value={item}
              onlyCountries={['us']} // ? change to `preferredCountries` once international numbers are allowed
              placeholder={`Add ${title}`}
              onKeyDown={handleKeyDown}
              onChange={(item, data, event, formattedValue) => handleUpdatePhone(formattedValue)}
            />
          )}

          <InputGroupAddon addonType="append">
            <Button
              color="success"
              type="button"
              onClick={(item) => handleButtonClick(item)}
            >
              <FontAwesomeIcon
                icon="plus"
                className="fas fa-fw fa-sm fa-plus"
                aria-hidden="true"
              />
            </Button>
          </InputGroupAddon>
        </InputGroup>
      </div>
    </div>
  );
};

ContactTable.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  items: PropTypes.array,
  addItem: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
  setAlias: PropTypes.func.isRequired,
  sendVerification: PropTypes.func.isRequired,
};

ContactTable.defaultProps = {
  items: undefined,
};

export default ContactTable;
