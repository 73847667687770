import React from 'react';
import PropTypes from 'prop-types';

const Page = ({ isActive, header, children }) => {
  return (
    <div className={`page ${isActive ? 'is-active' : ''}`}>
      <div className="page__header">{header}</div>
      <div className="page__body">{children}</div>
    </div>
  );
};

Page.propTypes = {
  isActive: PropTypes.bool.isRequired,
  header: PropTypes.string,
  children: PropTypes.any,
};

Page.defaultProps = {
  header: undefined,
};

export default Page;
