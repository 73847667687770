import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import axios from 'axios';
import PortalAuthContext from './PortalAuthContext';
import { setUserTokens } from './helpers/setUserTokens';
import { redirectToHpa, signOutHpa } from './helpers/portalHelper';
import { checkClaims } from './helpers/checkClaims';
import { session } from './helpers/session';
import { getAccount } from '../../clients/identity';
const { jwtDecode } = require('jwt-decode');
const PortalIntegrator = (props) => {
  const [error, updateError] = useState(undefined);
  const [user, updateUser] = useState(undefined);
  const [loggedIn, updateLoggedIn] = useState(false);
  const [authorized, updateAuthorization] = useState(false);
  const [passwordComplexityFeature, updatePasswordComplexityFeature] = useState(false);
  const [tokenExpired, toggleTokenExpiration] = useState(false);
  const [loading, toggleLoading] = useState(true);
  const [queryParameters, updateQueryParameters] = useState(undefined);

  useEffect(() => {
    const checkIfUserIsLoggedIn = async () => {
      try {
        let accessToken;
        let idToken;
        let params;

        params = qs.parse(window.location.search, {
          ignoreQueryPrefix: true,
        });

        // if the token was not in storage, check the query string to see if one was sent on a redirect
        if (params.access_token) {
          accessToken = params.access_token;
          idToken = params.id_token;
          updateQueryParameters(window.location.search);
          window.history.replaceState({}, window.location.hostname, '/');
          accessToken && setUserTokens(idToken, accessToken);
          var referrer = window.document.referrer.slice(0, -1)
          localStorage.setItem('Referrer', referrer);
        }
        else {
          accessToken = localStorage.getItem('Hstm.Identity.AccessToken');
          idToken = localStorage.getItem('Hstm.Identity.IdentityToken');
        }

        const identityClaims = jwtDecode(accessToken);

        if (props.requiredClaims && props.requiredClaims.length > 0) {
          const claimCheckResult = checkClaims(
            props.requiredClaims,
            identityClaims,
          );
          updateAuthorization(claimCheckResult);
        } else {
          updateAuthorization(true);
        }

        if (Date.now() >= identityClaims.exp * 1000) {
          toggleTokenExpiration(true);
        }

        try {
          const response = await getAccount();
          const account = response.data;
          accessToken && updateUser({ account });
          accessToken && updateLoggedIn(true);
          toggleLoading(false);
        } catch (error) {
          updateError(error.status >= 500 ? 'transient' : 'request');
          throw error;
        }
        try {
          const response = await axios.get(
            `${props.config.identityApiBaseUrl}/manage/feature`,
            {},
          );
          const feature = response.data;
          let getfeature = 0;
          for (let index = 0; index < feature.length; index++) {
            if (feature[index].featureCode == "Password_Complexity_Rules") {
              getfeature = feature[index].isActive;
              break;
            }
          }
          if (getfeature == 1) { accessToken && updatePasswordComplexityFeature(true); }
        } catch (error) {
          updatePasswordComplexityFeature(false);
        }
      } catch (error) {
        localStorage.clear();
        updateAuthorization(false);
        updateLoggedIn(false);
        toggleLoading(false);
      }
    };

    checkIfUserIsLoggedIn();
  }, []);

  const redirectUserToHpa = (destination) => {
    redirectToHpa(props.config, destination);
  };

  const signOut = () => {
    signOutHpa(props.config);
  };

  function expirationCallback() {
    toggleTokenExpiration(true);
  }

  session.check(tokenExpired, expirationCallback);

  const contextValues = {
    user,
    loggedIn,
    authorized,
    tokenExpired,
    redirectUserToHpa,
    loading,
    error,
    updateUser,
    updateLoggedIn,
    updateAuthorization,
    toggleLoading,
    toggleTokenExpiration,
    updateError,
    queryParameters,
    passwordComplexityFeature,
    signOut,
  };
  return (
    <PortalAuthContext.Provider value={contextValues}>
      {props.children}
    </PortalAuthContext.Provider>
  );
};

PortalIntegrator.propTypes = {
  requiredClaims: PropTypes.array,
  config: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
};

export default PortalIntegrator;

export { PortalAuthContext };
