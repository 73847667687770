import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  FormFeedback,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PasswordInput = ({
  name,
  label,
  value,
  validation,
  setValue,
  showPassword,
  toggleShowPassword,
}) => {
  return (
    <FormGroup>
      <Label for={name} className="form-label">
        {label}
      </Label>
      <InputGroup>
        <Input
          type={showPassword ? 'text' : 'password'}
          name={name}
          value={value}
          id={name}
          className="form-control"
          onChange={(e) => setValue(e.target.value)}
          invalid={!!validation}
        />
        <InputGroupAddon addonType="append">
          <Button
            color="primary"
            outline={true}
            onClick={() => toggleShowPassword(!showPassword)}
          >
            {showPassword ? (
              <FontAwesomeIcon
                icon="eye"
                className="fas fa-eye"
                aria-hidden="true"
              />
            ) : (
              <FontAwesomeIcon
                icon="eye-slash"
                className="fas fa-eye-slash"
                aria-hidden="true"
              />
            )}
          </Button>
        </InputGroupAddon>
      </InputGroup>
      {validation && <FormFeedback>{validation}</FormFeedback>}
    </FormGroup>
  );
};

PasswordInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  validation: PropTypes.string,
  showPassword: PropTypes.bool.isRequired,
  toggleShowPassword: PropTypes.func.isRequired,
};

PasswordInput.defaultProps = {
  validation: undefined,
};

export default PasswordInput;
