import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from 'reactstrap';
import { required } from '../../../../helpers/validate';
import { changeName } from '../../../../clients/identity';

const PersonalPage = ({ account, setHeader, updateUser }) => {
  const [name, updateNameValue] = useState({
    firstName: account.firstName,
    lastName: account.lastName,
  });
  const [firstNameValid, setFirstNameValid] = useState(
    required(name.firstName),
  );
  const [lastNameValid, setLastNameValid] = useState(required(name.lastName));

  function handleUpdateName(e) {
    const newName = Object.assign({}, name);
    newName[e.target.id] = e.target.value.trim();
    updateNameValue(newName);
  }

  async function handleFormSubmit(e) {
    e.preventDefault();

    setFirstNameValid(required(name.firstName));
    setLastNameValid(required(name.lastName));

    if (name.firstName === '' || name.lastName === '') {
      return;
    }
    try {
      await changeName(name.firstName, name.lastName);
      account.firstName = name.firstName;
      account.lastName = name.lastName;
      updateUser({ account });
      createAlert('success', 'Your personal information was updated.');
    } catch (error) {
      createAlert(
        'danger',
        'There was a problem changing your personal information.',
      );
    }
  }

  function createAlert(type, text) {
    setHeader(
      <Alert
        color={type}
        isOpen={true}
        toggle={() => {
          setHeader(undefined);
        }}
      >
        {text}
      </Alert>,
    );
  }

  return (
    <Form onSubmit={handleFormSubmit}>
      <FormGroup>
        <Label for="firstName" className="form-label">
          First Name
        </Label>
        <Input
          type="text"
          name="firstName"
          id="firstName"
          onChange={handleUpdateName}
          placeholder="first name"
          className="form-control"
          value={name.firstName}
          invalid={!!firstNameValid}
        />
        {firstNameValid && <FormFeedback>{firstNameValid}</FormFeedback>}
      </FormGroup>
      <FormGroup>
        <Label for="lastName" className="form-label">
          Last Name
        </Label>
        <Input
          type="text"
          name="lastName"
          id="lastName"
          onChange={handleUpdateName}
          placeholder="last name"
          className="form-control"
          value={name.lastName}
          invalid={!!lastNameValid}
        />
        {lastNameValid && <FormFeedback>{lastNameValid}</FormFeedback>}
      </FormGroup>
      <Button color="primary" onClick={handleFormSubmit}>
        Update
      </Button>
    </Form>
  );
};

PersonalPage.propTypes = {
  account: PropTypes.object.isRequired,
  setHeader: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
};

export default PersonalPage;
