import newRelic from '../helpers/newRelic';

export function formatNrpId(rawId) {
  if (rawId === undefined || typeof rawId !== 'string') {
    newRelic.noticeError('Must provide a string for the nrp Id');
    return rawId;
  }

  let firstThreeDigits = rawId.slice(0, 3);
  let lastFourDigits = rawId.slice(3, 7);
  return `${firstThreeDigits}-${lastFourDigits}`;
}
