export function redirectToHpa(config, destination) {
  let hpaUrl = getHpaUrlFromConfig(config);
  const destinationEndpoint = destination === 'register' ? '' : 'sign-in';
  localStorage.clear();
  const port = window.location.port === '' ? '' : `:${window.location.port}`;
  const redirectUrl = `${window.location.protocol}//${window.location.hostname}${port}`;
  window.location.replace(
    `${hpaUrl}/account/${destinationEndpoint}?redirect_uri=${redirectUrl}&force_sign_in=true&response_type=access`,
  );
}

export function signOutHpa(config) {
  let hpaUrl = getHpaUrlFromConfig(config);
  localStorage.clear();
  const port = window.location.port === '' ? '' : `:${window.location.port}`;
  const redirectUrl = `${window.location.protocol}//${window.location.hostname}${port}`;
  window.location.replace(
    `${hpaUrl}/account/sign-out?redirect_uri=${redirectUrl}&force_sign_in=true&response_type=access`,
  );
}

function getHpaUrlFromConfig(config){
  let hpaURL = localStorage.getItem('Referrer');
  if (hpaURL == null || hpaURL.length == 0) {
    hpaURL = config.hpaBaseUrl;
  }
  return hpaURL;
}
