/* eslint-disable no-console */

const nr = {
  noticeError(err) {
    console.error(err);
  },
};

const newRelic = window.newrelic || nr;
export default newRelic;
