import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './Containers/App';
import PortalIntegrator from './Components/PortalIntegrator';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/hstream.css';

const config = window['myhstreamRuntimeConfig'] ;


ReactDOM.render(
  <PortalIntegrator config={config}>
    <App />
  </PortalIntegrator>,
  document.getElementById('app'),
);
