import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  NrpCardWrapper,
  NrpCardTitle,
  NrpLineItem,
  NrpLineItemStatus,
  NrpLogoWrapper,
  NrpLogo,
  LinkedOrganizationsButton,
  OrganizationsDropdown,
  LinkedOrganizationsWrapper,
  DropdownWrapper,
  LinkedOrganizationsRow,
} from './style';
import { Row, Col } from 'reactstrap';
import logo from '../../images/nrp-logo-card.png';
import { formatNrpId } from '../../helpers/formatNrpId';
import { formatDate } from '../../helpers/formatDate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const NrpCard = ({ nrpDetails }) => {
  const {
    nrpId,
    nrpName,
    nrpRank,
    nrpStatus,
    expirationDate,
    organization,
  } = nrpDetails;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);

  return (
    <>
      <NrpCardWrapper dropdownOpen={dropdownOpen === false}>
        <Col sm="12">
          <Row>
            <NrpCardTitle id="nrp-card-title">NRP Card</NrpCardTitle>
          </Row>
          <Row>
            <NrpLineItem id="nrp-card-id">
              ID: <strong>{formatNrpId(nrpId)}</strong>
            </NrpLineItem>
          </Row>
          <Row>
            <NrpLineItem id="nrp-card-name">
              Name: <strong>{nrpName}</strong>
            </NrpLineItem>
          </Row>
          <Row>
            <NrpLineItem id="nrp-card-rank">
              Rank: <strong>{nrpRank}</strong>
            </NrpLineItem>
          </Row>
          <Row>
            <NrpLineItemStatus
              id="nrp-card-status"
              active={nrpDetails.nrpStatus}
            >
              Status: <strong>{nrpStatus}</strong>
            </NrpLineItemStatus>
          </Row>
          <Row>
            <NrpLineItem id="nrp-card-expiration">
              Expiration Date: <strong>{formatDate(expirationDate)}</strong>
            </NrpLineItem>
          </Row>
        </Col>
        <LinkedOrganizationsRow className="row" id="nrp-card-organizations">
          <LinkedOrganizationsButton
            id="linked-organizations-dropdown-button"
            onClick={() => {
              toggle();
            }}
          >
            <p>Linked Organizations</p>
            <FontAwesomeIcon
              icon={dropdownOpen === false ? faChevronDown : faChevronUp}
            />
          </LinkedOrganizationsButton>
          <NrpLogoWrapper>
            <NrpLogo
              id="nrp-card-logo"
              src={logo}
              dropdownOpen={dropdownOpen}
            />
          </NrpLogoWrapper>
        </LinkedOrganizationsRow>
      </NrpCardWrapper>
      <DropdownWrapper id="nrp-card-organization-dropdown">
        {dropdownOpen === true && nrpDetails.organization !== undefined && (
          <LinkedOrganizationsWrapper>
            <OrganizationsDropdown id="organizations-dropdown">
              <p>{organization}</p>
            </OrganizationsDropdown>
          </LinkedOrganizationsWrapper>
        )}
      </DropdownWrapper>
    </>
  );
};

NrpCard.propTypes = {
  nrpDetails: PropTypes.shape({
    nrpId: PropTypes.string,
    nrpName: PropTypes.string,
    nrpRank: PropTypes.string,
    nrpStatus: PropTypes.string,
    expirationDate: PropTypes.string,
    organization: PropTypes.string,
  }),
};

NrpCard.defaultProps = {
  nrpDetails: undefined,
};

export default NrpCard;
