import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Faq = ({ question, children }) => {
  const [active, setActive] = useState(false);

  return (
    <div className={`page__faq ${active && 'is-active'}`}>
      <div className="page__faq-question" onClick={() => setActive(!active)}>
        <h3>{question}</h3>
        <Button color="link">
          <FontAwesomeIcon
            icon="chevron-right"
            className="fa-lg"
            aria-hidden="tru"
          />
        </Button>
      </div>
      <div className="page__faq-response">{children}</div>
    </div>
  );
};

Faq.propTypes = {
  question: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Faq;
