import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { Button } from 'reactstrap';

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ErrorText = styled.div`
  text-align: center;
  padding-left: 2em;
  padding-right: 2em;
`;

const CardsError = ({ retry }) => {
  return (
    <ErrorWrapper>
      <FontAwesomeIcon
        icon="exclamation-triangle"
        aria-hidden="true"
        style={{
          fontSize: '5em',
          paddingBottom: '.2em',
          marginLeft: 'auto',
          marginRight: 'auto',
          color: 'orange',
        }}
      />
      <ErrorText>
        I{"'"}m sorry, we encountered an error while requesting the cards for
        your hStream ID. Please try again.
      </ErrorText>
      <Button style={{ marginTop: '2em' }} onClick={retry} color="primary">
        Retry
      </Button>
    </ErrorWrapper>
  );
};

CardsError.propTypes = {
  retry: PropTypes.func.isRequired,
};

export default CardsError;
