import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const UserAvatar = ({ name }) => {
  return (
    <div className="site__user">
      <span className="site__user-avatar">
        <FontAwesomeIcon
          icon="user-circle"
          className="fas fa-2x fa-user-circle"
          aria-hidden="true"
        />
      </span>
      <span className="site__user-name">{name}</span>
    </div>
  );
};

UserAvatar.propTypes = {
  name: PropTypes.string.isRequired,
};

export default UserAvatar;
