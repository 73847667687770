import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as identity from '../../../../clients/identity';
import NrpCard from '../../../../Components/NrpCard';
import styled from 'styled-components';
import { Spinner } from 'reactstrap';
import CardsError from './CardsError';

const PageHeader = styled.p`
  font-weight: 600;
  margin-left: 1rem;
`;

const NoLinksMessage = styled.p`
  color: grey;
  margin-top: 1rem;
  padding-left: 1rem;
`;

const LoadingWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
`;

const CardsPage = () => {
  const [loading, toggleLoading] = useState(true);
  const [error, updateError] = useState(false);
  const [nrpDetails, updateNrpDetails] = useState(undefined);
  useEffect(() => {
    handleAffiliationsRetrieval();
  }, []);

  const handleAffiliationsRetrieval = async () => {
    try {
      updateError(false);
      toggleLoading(true);
      const affiliations = await identity.getAffiliations();
      if (affiliations.affiliations.length > 0) {
        filterAffiliations(affiliations.affiliations);
      }
      toggleLoading(false);
    } catch (err) {
      if (err.response && err.response.status != 404) {
        updateError(true);
      }
      toggleLoading(false);
    }
  };

  const filterAffiliations = (affiliations) => {
    if (affiliations == null || affiliations.length == 0) {
      return;
    }

    affiliations.forEach((affiliation) => {
      const currentNrpCard = affiliation.nrpCards.find(
        (c) => c.isCurrent === true,
      );

      if (currentNrpCard) {
        toggleLoading(false);
        updateNrpDetails({
          ...nrpDetails,
          nrpId: currentNrpCard.nrpId,
          nrpName: currentNrpCard.certificationName,
          nrpRank: currentNrpCard.nrpRank,
          nrpStatus: currentNrpCard.nrpStatus,
          expirationDate: currentNrpCard.expirationDate,
          organization: affiliation.organization.name,
        });
      }
    });
    toggleLoading(false);
  };

  if (loading) {
    return (
      <div>
        <PageHeader>Cards</PageHeader>
        <div style={{ display: 'flex' }}>
          <LoadingWrapper>
            <Spinner size="lg" color="primary" />
          </LoadingWrapper>
        </div>
      </div>
    );
  }
  return (
    <div className="cardsPage" width="50%">
      <PageHeader>Cards</PageHeader>
      {nrpDetails ? (
        <NrpCard width="50%" nrpDetails={nrpDetails} />
      ) : error ? (
        <CardsError statusCode={error} retry={handleAffiliationsRetrieval} />
      ) : (
        <NoLinksMessage id="no-cards-message">
          You do not have any cards associated with your hStream ID yet.
        </NoLinksMessage>
      )}
    </div>
  );
};

CardsPage.propTypes = {
  account: PropTypes.object,
  userAccount: PropTypes.object,
  user: PropTypes.object,
};

CardsPage.defaultProps = {
  account: undefined,
  userAccount: undefined,
  user: undefined,
};

export default CardsPage;
