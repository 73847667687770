import React from 'react';
import Faq from '../../../../Components/Faq';
import styled from 'styled-components';

const IndentedText = styled.p`
  margin-left: 1.5em;
`;

const FaqPage = () => {
  return (
    <>
      <Faq question="What is an hStream ID?">
        <p>
          Your hStream ID is the account you use to access a growing number of applications within
          the HealthStream ecosystem, like the Resuscitation Skills mobile
          app. You can manage your hStream ID credentials in the My hStream site, reset
          your password, and update your personal information.
        </p>
      </Faq>
      <Faq question="Where can I use my hStream ID?">
        <p>
          <strong>Some of the Applications and Products using hStream ID today:</strong>
        </p>
        <ul>
          <li>American Red Cross Resuscitation Suite&trade;</li>
          <li>Resuscitation Skills App</li>
          <li>RepDirect&trade; by HealthStream</li>
          <li>AMPT&trade;</li>
          <li>Community</li>
          <li>Neonatal Resuscitation Program<sup>&#174;</sup> (NRP<sup>&#174;</sup>)</li>
          <li>ePortfolio&trade;</li>
        </ul>
      </Faq>
      <Faq question="Why do I receive emails/texts about my account?">
        <p>
        For security reasons, after your account is created, we will email or text 
        you when your account changes:
        </p>
        <ul>
          <li>New hStream ID account is verified.</li>
          <li>Account password is reset.</li>
          <li>New email address or mobile phone number is associated to your account.</li>
          <li>Email address or mobile phone number you login with is changed.</li>
        </ul>
        <p>
        If you did not make or request the change we contact you about, please login to 
        manage your account and change your password. 
        </p>
        <p>
        Use the following password best practices:
        </p>
        <ul>
          <li>Keep your password safe, and never share it with anyone.</li>
          <li>Create a password that is hard to guess.</li>
          <li>Do not re-use passwords for your online accounts.</li>
        </ul>
        <p>
        You can also contact us by calling (800) 521-0574.
        </p>
      </Faq>
      <Faq question="Is my hStream ID different from my HealthStream ID?">
        <p>
          Yes, they are two separate credentials. Your
          hStream ID replaced your HealthStream ID and provides more
          self-management features for your account such as:
        </p>
        <ul>
          <li>Self-service for password reset.</li>
          <li>
            More secure, but simpler password requirements following the
            National Institute of Standards and Technology (NIST) guidelines.
          </li>
          <li>
            Ability to login to a growing number of HealthStream apps and
            products using an email address or a mobile phone number.{' '}
          </li>
        </ul>
      </Faq>
      <Faq question="Why should I use my personal email address for my hStream ID?">
        <p>
          Your hStream ID belongs to you as an individual and will travel with you over 
          your career. Use an email address you will never lose access to, so you don’t 
          experience a disruption with your account.
        </p>
      </Faq>
      <Faq question="Why am I prompted to provide my phone number?">
        <p>
          A phone number allows us to help you with account recovery and
          password reset. It can also be used to login. We will never ask you for your hStream ID password.
        </p>
      </Faq>
      <Faq question="Can I use an international phone number?">
        <p>
          hStream ID only supports United States phone numbers (US/USA +1)
          at this time. If you need assistance or have questions regarding your
          phone number as an international user, please connect with our
          customer support team.
        </p>
        <IndentedText>
          Phone: 800.521.0574 <br /> Mon-Fri 7AM - 7PM CST <br /> Closed
          Holidays
        </IndentedText>
      </Faq>
      <Faq question="Who do I contact for assistance with my hStream ID?">
        <p>
          If you need assistance or have questions regarding your hStream ID,
          please connect with our customer support team.
        </p>
        <IndentedText>
          Phone: 800.521.0574 <br /> Mon-Fri 7AM - 7PM CST <br /> Closed
          Holidays
        </IndentedText>
      </Faq>
    </>
  );
};

export default FaqPage;
