const { jwtDecode } = require('jwt-decode');

export const session = {
  check: function check(tokenExpired, expirationCallback) {
    const checkDelay = 1000;
    const accessToken = localStorage.getItem('Hstm.Identity.AccessToken');

    if (accessToken) {
      const tokenData = jwtDecode(accessToken);
      if (!tokenExpired && tokenData && Date.now() >= tokenData.exp * 1000) {
        expirationCallback();
      }
    }
    clearTimeout(session.timeout);
    setTimeout(
      () => session.check(tokenExpired, expirationCallback),
      checkDelay,
    );
  },
};
