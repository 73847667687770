import React, { useState, useEffect, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import PersonalPage from './components/PersonalPage';
import ContactPage from './components/ContactPage';
import Page from '../../Components/Page';
import PasswordPage from './components/PasswordPage';
import FaqPage from './components/FaqPage';
import Navigation from '../../Components/Navigation';
import NavigationLink from '../../Components/NavigationLink';
import { getAccount } from '../../clients/identity';
import { PortalAuthContext } from '../../Components/PortalIntegrator';
import styled from 'styled-components';
import { Button } from 'reactstrap';
import CardsPage from './components/CardsPage';
import LinkedApplicationsPage from './components/LinkedApplicationsPage';
import { getLinkedApplications } from '../../clients/identity';
import { faAddressCard } from '@fortawesome/free-solid-svg-icons';
import { initializePendo } from '../../helpers/pendo';

const AccountManagement = ({ mobileNav, toggleMobileNav, signOut, signIn }) => {
  const { loggedIn, user, updateUser } = useContext(PortalAuthContext);
  const [activePage, setActivePage] = useState('personal');
  const [personalPageHeader, setPersonalPageHeader] = useState(undefined);
  const [contactPageHeader, setContactPageHeader] = useState(undefined);
  const [passwordPageHeader, setPasswordPageHeader] = useState(undefined);
  const [refreshState, updateState] = useState();
  const [linkedApplicationsdata, updateLinkedApplicationsdata] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => toggleMobileNav(false), [activePage]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAccount();
        const account = response.data;
        updateUser({ account });
      } catch (error) {
        //todo: handle this
      }
    };

    if (loggedIn) {
      fetchData();
    }
  }, [refreshState]);

  useEffect(() => {
    if (!loggedIn) {
      setPersonalPageHeader(undefined);
      setActivePage('personal');
    }
  }, [loggedIn]);

  // Pendo
  useEffect(() => {
    if (loggedIn) {
      initializePendo(user.account);
    }
  }, [loggedIn]);

  const fetchLinkedApplications = async () => {
    try {
      setIsLoading(true);
      const response = await getLinkedApplications(
        user && user.account && user.account.hstreamId,
      );
      updateLinkedApplicationsdata(response && response.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const personalPageIsActive = activePage === 'personal';
  const contactPageIsActive = activePage === 'contact';
  const passwordPageIsActive = activePage === 'password';
  const faqPageIsActive = activePage === 'faq';
  const linkPageIsActive = activePage === 'cards';
  const newLinkPageIsActive = activePage === 'newlinks';

  const PersonalPageAnonymous = styled.div`
    color: #343a40;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    p {
      margin: 2rem 0;
      max-width: 400px;
    }
  `;

  return (
    <>
      <Navigation
        mobileNav={mobileNav}
        toggleMobileNav={toggleMobileNav}
        user={user}
        signOut={signOut}
        signIn={signIn}
        loggedIn={loggedIn}
      >
        <NavigationLink
          id="personal"
          label="Personal Information"
          icon="user"
          isActive={personalPageIsActive}
          setActivePage={setActivePage}
        />
        <NavigationLink
          id="contact"
          label="Email &amp; Phone"
          icon="address-book"
          isActive={contactPageIsActive}
          setActivePage={setActivePage}
          disabled={!loggedIn}
        />
        <NavigationLink
          id="cards"
          label="Cards"
          icon={faAddressCard}
          isActive={linkPageIsActive}
          setActivePage={setActivePage}
          disabled={!loggedIn}
        />
        <NavigationLink
          id="linkedapps"
          label="Linked Applications"
          icon="link"
          isActive={newLinkPageIsActive}
          disabled={!loggedIn}
          setActivePage={() => {
            setActivePage('newlinks');
            fetchLinkedApplications();
          }}
        />
        <NavigationLink
          id="password"
          label="Reset Password"
          icon="lock"
          isActive={passwordPageIsActive}
          setActivePage={setActivePage}
          disabled={!loggedIn}
        />
        <NavigationLink
          id="faq"
          label="FAQ"
          icon="question-circle"
          isActive={faqPageIsActive}
          setActivePage={setActivePage}
        />
      </Navigation>
      <main className="site__main">
        <Page isActive={personalPageIsActive} header={personalPageHeader}>
          {loggedIn ? (
            <PersonalPage
              account={user.account}
              setHeader={setPersonalPageHeader}
              updateUser={updateUser}
            />
          ) : (
            <PersonalPageAnonymous>
              <h4>Personal Information</h4>
              <p>
                To make changes to your hStream ID like your name, email and
                password, please sign in.
              </p>
              <Button color="primary" onClick={signIn}>
                Sign In
              </Button>
            </PersonalPageAnonymous>
          )}
        </Page>
        <Page isActive={contactPageIsActive} header={contactPageHeader}>
          {loggedIn && user && (
            <ContactPage
              account={user.account}
              setHeader={setContactPageHeader}
              updateUser={updateUser}
              forceUpdate={forceUpdate}
            />
          )}
        </Page>
        <Page isActive={passwordPageIsActive} header={passwordPageHeader}>
          <PasswordPage setHeader={setPasswordPageHeader} />
        </Page>
        <Page isActive={faqPageIsActive}>
          <FaqPage />
        </Page>
        <Page isActive={linkPageIsActive}>
          {loggedIn && user && <CardsPage account={user.account} />}
        </Page>
        <Page isActive={newLinkPageIsActive}>
          <LinkedApplicationsPage
            linkedApplicationsdata={
              linkedApplicationsdata && linkedApplicationsdata.applications
            }
            isLoading={isLoading}
          />
        </Page>
      </main>
    </>
  );
};

AccountManagement.propTypes = {
  mobileNav: PropTypes.bool.isRequired,
  toggleMobileNav: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
  signIn: PropTypes.func,
};

AccountManagement.defaultProps = {
  signIn: undefined,
};

export default AccountManagement;
