/* eslint-disable no-console */

export function initializePendo(account) {
  // user returned from cognito
  const { firstName, lastName, hstreamId, emails } = account;

  // in absence of account information use app name
  const appName = 'MYHSTREAM';

  // find the first verified email marked as an alias
  const match = emails.filter((item) => item.isAlias && item.verified).pop();
  const email = match ? match.value : null;

  // create the pendo user object
  const pendoUser = {
    visitor: {
      id: hstreamId,
      email,
      name: `${firstName} ${lastName}`,
    },
    account: {
      id: appName,
      name: appName,
    },
  };

  // initialize pendo if available
  if (window.pendo) {
    window.pendo.initialize(pendoUser);
  } else {
    console.error('Pendo not found, unable to initialize user', pendoUser);
  }
}
